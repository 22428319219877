import styled from 'styled-components';
import logoImage from '../../assets/images/imagotipo.png';
import { Link } from 'gatsby';

export const Container = styled.div`
    background: linear-gradient(303.52deg, #a8cf45 26.23%, #00afef 77.92%);
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    padding-top: 16px;
    padding-bottom: 30px;
`;

export const ContentContainer = styled.div`
    display: flex;
    padding-top: 60px;
    padding-left: 20vw;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
        padding-left: 10vw;
    }
`;
export const GraphContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 20px;
    padding-left: 0px;
    height: 350px;
    font-size: 12px;
    width: 60vw;
    background-color: #fafafa;
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
        height: 300px;
        width: 80vw;
    }
`;
export const Logo = styled(Link)`
    width: 153px;
    height: 46px;
    background: url(${logoImage}) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-right: auto;
    display: flex;
    margin-left: 48px;
    padding-top: 16px;
`;

export const Title = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    color: #faf9f9;
    margin-bottom: 14px;
    @media screen and (max-width: 400px) {
        font-size: 20px;
        line-height: 23px;
    }
`;

export const SubTitle = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #faf9f9;
    width: 510px;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
        width: 80vw;
        font-size: 14px;
    }
`;

export const Button = styled(Link)`
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #fafafa;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 163px;
    height: 29px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    cursor: pointer;
    background-color: transparent;
    :hover {
        opacity: 0.5;
    }
`;

export const TooltipContainer = styled.div`
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
`;

export const TooltipSubTitle = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: rgb(242, 153, 74);
`;
export const TooltipTitle = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: black;
    margin-bottom: 6px;
`;

export const NoDataContainer = styled.div`
    width: 507px;
    height: 359px;

    background: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;
    @media screen and (max-width: 768px) {
        width: 80vw;
    }
`;

export const NoDataTitle = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;

    text-align: center;

    color: #95bb34;
`;

export const NoDataSubtitle = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    text-align: center;

    color: #95bb34;
`;
