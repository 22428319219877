import * as React from 'react';
import Graphs from '../components/Graphs';

const GraphsPage = ({ location }) => {
    const { state = {} } = location;
    let email;
    state !== null ? (email = state.email) : email = '';
    return <Graphs email={email} />;
};

export default GraphsPage;
