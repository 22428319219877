import React, { useEffect, useState } from 'react';
import { graphsText } from '../../assets/data';
import { useQuery } from '@apollo/react-hooks';
import { GET_GARBAGE_STATISTICS } from '../../queries/getGarbageData';

import {
    Container,
    Title,
    SubTitle,
    ContentContainer,
    GraphContainer,
    NoDataContainer,
    NoDataSubtitle,
    NoDataTitle,
    Logo,
    Button,
    TooltipContainer,
    TooltipSubTitle,
    TooltipTitle,
} from './GraphsElements';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Bar, Line, ComposedChart } from 'recharts';

const Footer = ({ email }) => {
    const { loading, data = [] } = useQuery(GET_GARBAGE_STATISTICS, {
        variables: { email },
    });
    const [dataAcum, setDataAcum] = useState([]);

    const organizeData = () => {
        let index = 0,
            date,
            acum = 0;
        data.garbageStatistics.length !== 0
            ? (date = generateDate(data.garbageStatistics[0].date))
            : (date = '');
        const dataAcum = [];
        while (index < data.garbageStatistics.length) {
            let weightAcum = 0,
                previousDate = date;

            while (index <= data.garbageStatistics.length - 1 && date === previousDate) {
                weightAcum += data.garbageStatistics[index].weight;
                if (index !== data.garbageStatistics.length - 1) {
                    date = generateDate(data.garbageStatistics[index + 1].date);
                }
                index++;
            }

            const oneDate = {
                ...data.garbageStatistics[index],
                weight: weightAcum,
                date: previousDate,
                totalGarbage: Math.floor(acum + weightAcum),
            };
            dataAcum.push(oneDate);
            acum = oneDate.totalGarbage;
        }
        return dataAcum;
    };

    const generateDate = (date) => {
        date = new Date(date).toLocaleDateString();
        return date;
    };

    useEffect(() => {
        if (!loading) {
            setDataAcum(organizeData());
        }
    }, [loading]);

    return (
        <>
            <Container>
                <Logo to="/" />
                <ContentContainer>
                    <Title>{graphsText.title}</Title>
                    {!loading && dataAcum.length !== 0 && (
                        <GraphContainer>
                            <ResponsiveContainer width="100%" height="100%">
                                <ComposedChart data={dataAcum}>
                                    <Line
                                        type="natural"
                                        dataKey="totalGarbage"
                                        fill="transparent"
                                        stroke="rgba(242, 153, 74, 1)"
                                    />

                                    <Bar
                                        barSize={15}
                                        dataKey="weight"
                                        fill="rgba(242, 153, 74, 1)"
                                    />
                                    <XAxis dataKey="date" tickFormatter={(date) => date} />
                                    <YAxis
                                        interval="preserveStartEnd"
                                        dataKey="totalGarbage"
                                        tickFormatter={(number) => `${number} kg.`}
                                    />
                                    <Tooltip content={<CustomToolTip />} />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </GraphContainer>
                    )}
                    {dataAcum.length === 0 && (
                        <NoDataContainer>
                            <NoDataTitle>Sin datos</NoDataTitle>
                            <NoDataSubtitle>{`asociados a ${email}`}</NoDataSubtitle>
                        </NoDataContainer>
                    )}

                    <Button to="/form">Volver a Oasis</Button>
                </ContentContainer>
            </Container>
        </>
    );
};

export default Footer;

const CustomToolTip = ({ active, payload, label }) => {
    if (active) {
        return (
            <TooltipContainer>
                <TooltipTitle>{label.toString().substr(0, 5) || ''}</TooltipTitle>
                <TooltipSubTitle>
                    {payload !== null ? `${payload[1].value} kg.` : ''}
                </TooltipSubTitle>
                <TooltipSubTitle>
                    {payload !== null ? `Total: ${payload[0].value} kg.` : ''}
                </TooltipSubTitle>
            </TooltipContainer>
        );
    }
    return null;
};
